@font-face {
  font-family: "TakeawaySans";
  font-weight: normal;
  font-display: block;
  src: url("~/static/fonts/TakeawaySans-Regular.woff") format("woff"),
  url("~/static/fonts/TakeawaySans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "TakeawaySans";
  font-weight: bold;
  font-display: block;
  src: url("~/static/fonts/TakeawaySans-Bold.woff") format("woff"),
  url("~/static/fonts/TakeawaySans-Bold.ttf") format("truetype");
}
