body.contrast-filter * {
  outline-color: #fff !important;
  background-color: #000 !important;
  color: #fff !important;
}

body.contrast-filter *::placeholder {
  color: #ff0 !important;
}

body.contrast-filter svg:not(.avoid-fill-stroke) * {
  fill: #fff;
  stroke: #fff;
}

body.contrast-filter .avoid-fill * {
  fill: none !important;
}

body.contrast-filter a,
body.contrast-filter a > span,
body.contrast-filter b,
body.contrast-filter blockquote,
body.contrast-filter button,
body.contrast-filter button > *,
body.contrast-filter canvas,
body.contrast-filter caption,
body.contrast-filter center,
body.contrast-filter cite,
body.contrast-filter code,
body.contrast-filter col,
body.contrast-filter colgroup,
body.contrast-filter dd,
body.contrast-filter details,
body.contrast-filter dfn,
body.contrast-filter dir,
body.contrast-filter div,
body.contrast-filter dl,
body.contrast-filter dt,
body.contrast-filter em,
body.contrast-filter embed,
body.contrast-filter fieldset,
body.contrast-filter figcaption,
body.contrast-filter figure,
body.contrast-filter font,
body.contrast-filter footer,
body.contrast-filter form,
body.contrast-filter header,
body.contrast-filter i,
body.contrast-filter iframe,
body.contrast-filter img,
body.contrast-filter input,
body.contrast-filter kbd,
body.contrast-filter label,
body.contrast-filter legend,
body.contrast-filter li,
body.contrast-filter mark,
body.contrast-filter menu,
body.contrast-filter meter,
body.contrast-filter nav,
body.contrast-filter nobr,
body.contrast-filter object,
body.contrast-filter ol,
body.contrast-filter option,
body.contrast-filter pre,
body.contrast-filter progress,
body.contrast-filter q,
body.contrast-filter s,
body.contrast-filter section,
body.contrast-filter select,
body.contrast-filter small,
body.contrast-filter span,
body.contrast-filter strike,
body.contrast-filter strong,
body.contrast-filter sub,
body.contrast-filter summary,
body.contrast-filter sup,
body.contrast-filter table,
body.contrast-filter td,
body.contrast-filter textarea,
body.contrast-filter th,
body.contrast-filter time,
body.contrast-filter tr,
body.contrast-filter tt,
body.contrast-filter u,
body.contrast-filter ul,
body.contrast-filter var,
body.contrast-filter a,
body.contrast-filter a span,
body.contrast-filter b,
body.contrast-filter blockquote,
body.contrast-filter button,
body.contrast-filter canvas,
body.contrast-filter caption,
body.contrast-filter center,
body.contrast-filter cite,
body.contrast-filter code,
body.contrast-filter col,
body.contrast-filter colgroup,
body.contrast-filter dd,
body.contrast-filter details,
body.contrast-filter dfn,
body.contrast-filter dir,
body.contrast-filter div,
body.contrast-filter dl,
body.contrast-filter dt,
body.contrast-filter em,
body.contrast-filter embed,
body.contrast-filter fieldset,
body.contrast-filter figcaption,
body.contrast-filter figure,
body.contrast-filter font,
body.contrast-filter footer,
body.contrast-filter form,
body.contrast-filter header,
body.contrast-filter i,
body.contrast-filter iframe,
body.contrast-filter img,
body.contrast-filter input,
body.contrast-filter kbd,
body.contrast-filter label,
body.contrast-filter legend,
body.contrast-filter li,
body.contrast-filter mark,
body.contrast-filter menu,
body.contrast-filter meter,
body.contrast-filter nav,
body.contrast-filter nobr,
body.contrast-filter object,
body.contrast-filter ol,
body.contrast-filter option,
body.contrast-filter pre,
body.contrast-filter progress,
body.contrast-filter q,
body.contrast-filter s,
body.contrast-filter section,
body.contrast-filter select,
body.contrast-filter small,
body.contrast-filter span,
body.contrast-filter strike,
body.contrast-filter strong,
body.contrast-filter sub,
body.contrast-filter summary,
body.contrast-filter sup,
body.contrast-filter table,
body.contrast-filter td,
body.contrast-filter textarea,
body.contrast-filter th,
body.contrast-filter time,
body.contrast-filter tr,
body.contrast-filter tt,
body.contrast-filter u,
body.contrast-filter ul,
body.contrast-filter var,
body.contrast-filter input::placeholder {
  color: #ff0 !important;
}

body.contrast-filter.white-border-on-contrast {
  border: 1px solid #fff;
}

body.contrast-filter svg.yellow-fill-on-contrast path,
body.contrast-filter div.yellow-fill-on-contrast svg path {
  stroke: #ff0 !important;
  fill: #ff0 !important;
}

body.contrast-filter .transparent-bg-on-contrast {
  background-color: transparent !important;
}

body.contrast-filter div.white-bg-on-contrast {
  background-color: #fff !important;
}

body.contrast-filter div.white-bg-on-contrast-tip-button {
  background-color: #fff !important;
  color: #000 !important;
}

body.contrast-filter i.white-bg-on-contrast {
  color: #000 !important;
  background-color: #fff !important;
}

body.contrast-filter button.white-bg-on-contrast {
  background-color: #fff !important;
  opacity: 1 !important;
}

body.contrast-filter button.selected-bg-on-contrast {
  background: #ff0 !important;
}

body.contrast-filter label.white-bg-on-contrast.checked:after {
  background: #ff0;
}

body.contrast-filter label.radio-label-focused.white-bg-on-contrast::after,
body.contrast-filter .checkbox-label-focused.white-bg-on-contrast::after {
  background: #fff;
}

body.contrast-filter .contrast-white-border-on-after-top::after {
  border-top-color: #fff;
}

body.contrast-filter div.white-border-on-contrast,
body.contrast-filter ul.white-border-on-contrast,
body.contrast-filter button:not([aria-disabled='true']),
body.contrast-filter input[type='button']:not([aria-disabled='true']),
body.contrast-filter input[type='submit']:not([aria-disabled='true']),
body.contrast-filter input[type='reset']:not([aria-disabled='true']) {
  border: 1px solid #fff !important;
}

body.outline-filter.contrast-filter .checkbox-label-focused {
  outline: 2px dashed #fff !important;
}

body.outline-filter .darkCover *:focus {
  outline: 2px dashed #fff !important;
}

body.outline-filter *:focus,
body.outline-filter .checkbox-label-focused {
  outline: 2px dashed #0a3847 !important;
}

body.contrast-filter label.radio-label-focused::before,
body.contrast-filter .checkbox-label-focused {
  box-shadow: 0 0 5px #fff !important;
}

body.contrast-filter svg.yellow-first-path > path:first-child {
  fill: #ff0;
}

body.contrast-filter svg circle.yellow-stroke {
  stroke: #ff0;
}
