body:not(.using-keyboard) *:focus {
	outline: none !important;
}

body:not(.outline-filter).using-keyboard label.radio-label-focused::before,
body:not(.outline-filter).using-keyboard .checkbox-label-focused {
	box-shadow: 0 0 5px #4D90FE;
}

body.outline-filter.using-keyboard label.radio-label-focused::before,
body.outline-filter.using-keyboard .checkbox-label-focused {
	box-shadow: 0 0 5px #0A3847;
}